import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import { Header } from "../../components/Header"
import { Layout } from "../../components/layout"
import { Footer } from "../../components/Footer"
import { Section } from "../../components/Section"
import { Container } from "../../components/Container"
import { Content } from "../../components/text/Content"
import { P } from "../../components/text/P"
import { FluidBackgroundImage } from "../../components/FluidBackgroundImage"

const CenteredContent = styled(Content)`
  margin-left: auto;
  margin-right: auto;
`

const CenteredP = styled(P)`
  text-align: center;
`

const H1White = styled.h1`
  text-align: center;
  color: white;
`

const H2 = styled.h2`
  color: ${(props) => props.theme.ascent};
`

const Page = (props) => {
  const page =
    props.data.allDataJson.edges[0].node.pages.products_fleet_management
  return (
    <Layout meta={page.meta} loadUikit={true}>
      <Header />
      <FluidBackgroundImage fluid={page.title_bg.childImageSharp.fluid}>
        <Section>
          <Container>
            <CenteredContent maxWidth={979}>
              <H1White>{page.title}</H1White>
              <CenteredP data={page.subtitle} />
            </CenteredContent>
          </Container>
        </Section>
      </FluidBackgroundImage>
      <Section>
        <Container>
          <Content maxWidth={898}>
            <H2>{page.trucks.title}</H2>
            <P data={page.trucks.desc} />
          </Content>
        </Container>
      </Section>
      <FluidBackgroundImage
        fluid={page.public_transits.bg.childImageSharp.fluid}
      >
        <Section>
          <Container>
            <Content maxWidth={898}>
              <H2>{page.public_transits.title}</H2>
              <P data={page.public_transits.desc} />
            </Content>
          </Container>
        </Section>
      </FluidBackgroundImage>
      <Footer />
    </Layout>
  )
}

export default Page

export const query = graphql`
  query {
    allDataJson {
      edges {
        node {
          pages {
            products_fleet_management {
              meta {
                url
                title
                description
                imageRootUrl
                image
              }
              title
              subtitle
              title_bg {
                childImageSharp {
                  fluid(maxWidth: 1440, quality: 80) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
              trucks {
                title
                desc
              }
              public_transits {
                title
                desc
                bg {
                  childImageSharp {
                    fluid(maxWidth: 1440, quality: 80) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
